import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { btn, btnInverse } from "../sass/04-components/button.module.scss"
import styles from "../sass/05-pages/home.module.scss"

import "../sass/index.scss"

import BannerBgImage from "../images/banner-bg-desktop.png"
import StyleSheetImage from "../images/auto-style-guides.svg"
import CoreUpdateImage from "../images/core-update.svg"
import A11yImage from "../images/a11y.svg"
import NpmPublishImage from "../images/npm-publish.svg"

import DevIcon from "../images/dev-icon.svg"
import DesignIcon from "../images/designer-icon.svg"
import MarketIcon from "../images/market-icon.svg"

import ReactIcon from "../images/react-icon.svg"
import VueIcon from "../images/vue-icon.svg"
import HtmlIcon from "../images/html-icon.svg"
import CssIcon from "../images/css-icon.svg"
import FigmaIcon from "../images/figma-icon.svg"
import SketchIcon from "../images/sketch-icon.svg"
import StorybookIcon from "../images/storybook-icon.svg"
import GithubIcon from "../images/github-icon.svg"
import SlackIcon from "../images/slack-icon.svg"

const IndexPage = () => {
  const [email, setEmail] = useState("");

  const onEmailAddressSubmit = (e) => {
    e.preventDefault();
    window.open(`https://airtable.com/shrcVMskHqgTFM3r8?prefill_Email=${email}`, "_blank");
    setEmail("");
  }

  return (
    <Layout>
      <SEO title="Home" />
      <div className={styles.banner}>
        <h1>The Design System Platform.</h1>
        <h2>Your product delivery is too expensive, inefficient, inconsistent and not accessible. Revolutionise your product delivery with Themework.</h2>
        <form onSubmit={onEmailAddressSubmit}>
          <label htmlFor="emailAddress" className="c-label">Email Address</label>
          <input className="c-input" id="emailAddress" type="email" placeholder="Email address" required onChange={e => setEmail(e.target.value)} value={email}/>
          <button type="submit" className={btn}>Request Beta Access</button>
        </form>
        <img src={BannerBgImage} alt="Banner Background" />
      </div>

      <div className={styles.features}>
        <div className={styles.featuresHeader} id="features">
          <span className="c-divider" />
          <div className={styles.featureHeading}>
            <h3>Start delivering your product now, and evolve with Themework. Forever.</h3>
          </div>
          <div className={styles.featureSubHeading}>
            <h4>Apply your brand to Themework’s production ready components. We will take care of evolution, accessibility and you can continue focusing on your product delivery goals.</h4>
          </div>
        </div>

        <div className={styles.featureHighLights}>
          <div className={styles.featureItem}>
            <img src={StyleSheetImage} alt="Auto Generated Style Guides" />
            <h4>Auto-generated style guides</h4>
            <p>To help better communicate your brand, style guides are generated from your company branding, hosted and ready to distribute with no setup.</p>
          </div>
          <div className={styles.featureItem}>
            <img src={CoreUpdateImage} alt="Auto Generated Style Guides" />
            <h4>Auto-updates from Themework Core</h4>
            <p>Your instance will be up-to-date with the latest browser and accessibility standards, supplied and tested by the Themework Core team.</p>
          </div>
          <div className={styles.featureItem}>
            <img src={A11yImage} alt="Auto Generated Style Guides" />
            <h4>Accessibility checker</h4>
            <p>No matter how your users access the web they can rely on a tested foundation, with custom accessibility checks to ensure your brand is accessible.</p>
          </div>
          <div className={styles.featureItem}>
            <img src={NpmPublishImage} alt="Auto Generated Style Guides" />
            <h4>NPM publishing</h4>
            <p>Publish your Themework instance directly to NPM, to control updates and get your product teams delivering straight away.</p>
          </div>
        </div>
      </div>

      <div className={styles.howItWorks}>
        <div className={styles.howItWorksInner}>
          <div className={styles.howItWorksHeader}>
            <h3>How Themework changes the way your teams deliver.</h3>
            <h4>For too long websites and web applications have been built with a high duplication of effort, with little documentation and poor accessibility coverage. These inefficiencies often lead to projects continuing with high maintance cost, or rebuilding far sooner than expected. Because at Themework we manage your reusable components, style guides and documentation, you can focus on your brand and start delivering with confidence.</h4>
          </div>

          <div className={styles.howItWorksFeatureWrapper}>
            <div className={styles.howItWorksFeature}>
              <div>
                <img src={DevIcon} alt="Developer Benefits" />
              </div>
              <h4>Developers</h4>
              <p>Empower developers to start building quicker. They can rely on a solid foundation, so they can focus on delivering product value.</p>
            </div>

            <div className={styles.howItWorksFeature}>
              <div>
                <img src={DesignIcon} alt="Developer Benefits" />
              </div>
              <h4>Designers</h4>
              <p>Design patterns and style guides gives design teams greater control. And ensures a smooth, well documented development handover.</p>
            </div>

            <div className={styles.howItWorksFeature}>
              <div>
                <img src={MarketIcon} alt="Developer Benefits" />
              </div>
              <h4>Marketers</h4>
              <p>Get your product to market quicker, reducing delivery costs while improving product efficiency and consistency.</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.callOut}>
        <div className={styles.callOutBlock}>
          <h4>Single source of truth</h4>
          <p>Code, design patterns, style guides and documentation are all in one place, allowing your product scale on a solid foundation that reduces maintenance cost.</p>
        </div>
        <div className={styles.callOutBlock}>
          <h4>Extensible</h4>
          <p>There will be times that Themework won't cover all of your needs, so you can add your own components and store them alongside your selected Themework ones.</p>
        </div>
      </div>

      <div className={styles.callOutIn}>
        <div className={styles.callOutInCopy}>
          <h3>Ready to find out more?</h3>
          <p>Get in touch for a demo</p>
        </div>
        <div className={styles.callOutInActions}>
          <a className={btn} href="https://airtable.com/shr0BW9N16uLibEXg" target="_blank" rel="noreferrer">Request a Demo</a>
          <a className={btnInverse} href="https://airtable.com/shrAb2aWA7S2MkMC0" target="_blank" rel="noreferrer">Contact Us</a>
        </div>
      </div>

      <div className={styles.integrations}>
        <div className={styles.integrationsInner}>
          <ul>
            <li><img src={ReactIcon} alt="React" /></li>
            <li><img src={VueIcon} alt="Vue" /></li>
            <li><img src={HtmlIcon} alt="HTML 5" /></li>
            <li><img src={CssIcon} alt="CSS 3" /></li>
            <li><img src={FigmaIcon} alt="Figma" /></li>
            <li><img src={SketchIcon} alt="Sketch" /></li>
            <li><img src={StorybookIcon} alt="Storybook" /></li>
            <li><img src={GithubIcon} alt="Github" /></li>
            <li><img src={SlackIcon} alt="Slack" /></li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
